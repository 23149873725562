@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  font-family: 'Inter', sans-serif;
}

@media screen and (min-width: 1024px) {
  .App-header {
    font-size: 25px;
  }
}




.App-link {
  color: #61dafb;
}

.images {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Adjust this value as needed */
  margin: 0 auto;
}

.image-item {
  position: relative;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

@media (min-width: 769px) {
  .image-item {
    width: 100%; /* Ensure full width on all screen sizes */
  }

  .media-item {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: contain;
    cursor: pointer;
    display: block;
    border-radius: 20px;
  }

  .category-name {
    text-align: center;
    margin: 20px 0;
  }
}

.image-item img, .image-item video {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  cursor: pointer;
  display: block;
  border-radius: 20px; /* Add this line for rounded edges */
}

.image-item h3 {
  margin: 0;
  padding: 5px 0;
  text-align: center;
  font-size: 16px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-item:hover .overlay {
  opacity: 1;
}

.image-controls {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .images {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-item {
    width: 95vw;
    margin: 10px 0;
    box-sizing: border-box;
  }

  .image-item img, .image-item video {
    width: 100%;
    height: auto;
    max-height: none;
    object-fit: contain;
  }

  .two-columns {
    display: flex;
    flex-direction: column;
  }

  .category-edit-item input {
    margin-right: 10px;
    font-size: 16px; /* Increase font size for better visibility */
    padding: 8px; /* Add more padding for a larger touch target */
    width: calc(100% - 50px); /* Make the input take up more width */
  }

  .category-editor {
    margin-top: 20px;
    padding: 15px; /* Increase padding for more space around the search bar */
  }

  .category-edit-item {
    display: flex;
    flex-direction: column; /* Stack input and button vertically on mobile */
    margin-bottom: 15px; /* Increase space between items */
  }

  .category-edit-item button {
    margin-top: 10px; /* Add space between input and button */
    padding: 10px; /* Increase button size for better touch target */
    font-size: 16px; /* Increase font size for better visibility */
  }
}

.upload-section {
  position: relative;
  margin-top: 20px; /* Add some space above the button */
}

.add-photo-button {
  background-color: transparent;
  border: 2px solid #000000; /* Change border color to black */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  color: #000000; /* Change icon color to black */
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px; /* Reduced from 30px to 15px */
}

.add-photo-button:hover {
  background-color: #000000; /* Change hover background to black */
  color: white;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.plus-icon {
  transition: transform 0.3s ease;
}

.plus-icon.minus {
  transform: rotate(45deg);
}

.upload-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.upload-form {
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
}

.upload-form h2 {
  color: #4A4A4A;
  font-size: 24px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4A4A4A;
  font-weight: 500;
}

.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  font-size: 16px;
  color: #4A4A4A;
}

.choose-file-btn, .upload-btn {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.choose-file-btn {
  background-color: #4ECDC4;
  color: white;
}

.choose-file-btn:hover {
  background-color: #45B7AC;
}

.upload-btn {
  background-color: #FF6B6B;
  color: white;
}

.upload-btn:hover {
  background-color: #FF8E8E;
}

.media-container {
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 15px;
}



@media (max-width: 768px) {
.media-item {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  cursor: pointer;
  display: block;
  border-radius: 20px;
}
}


@media (min-width: 769px) {
  .media-item {
    width: 100%;
    height: auto;
    max-height: 400px;
    min-height: 400px;
    cursor: pointer;
    display: block;
    border-radius: 20px;
  }
  .category-name {
    text-align: center;
    margin: 20px 0;
  }
  }

.menu-item-name {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  padding: 20px;
  text-align: left;
  font-size: 24px;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.025) 12.5%,
    rgba(0, 0, 0, 0.05) 25%,
    rgba(0, 0, 0, 0.1) 37.5%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0.35) 62.5%,
    rgba(0, 0, 0, 0.5) 75%,
    rgba(0, 0, 0, 0.65) 87.5%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.menu-item-name span {
  width: 100%;
}

.dot-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin-top: 5px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(109, 108, 108, 0.5);
  margin: 0 4px;
}

.dot.active {
  background-color: black;
}

.category-editor {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.category-edit-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.category-edit-item input {
  margin-right: 10px;
}

.category-section {
  margin-bottom: 30px;
}



@media (max-width: 768px) {

  .category-section h2 {
    text-align: left;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  
  
  .uncategorized-section h2 {
    text-align: left;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}

@media (min-width: 769px) {
  .category-section h2 {
    text-align: center;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  
  
  .uncategorized-section h2 {
    text-align: center;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}



.uncategorized-section {
  margin-top: 30px;
}


.image-controls select {
  margin-right: 10px;
  padding: 5px;
}

.image-controls button {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.image-controls button:hover {
  background-color: #45a049;
}

.new-restaurant-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.new-restaurant-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  margin: 20px auto;
}

.new-restaurant-form input {
  padding: 5px;
}

.new-restaurant-form button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.search-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.search-container form {
  display: flex;
  justify-content: center;
}

.search-container input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .search-container {
    max-width: 90%;
  }

  .search-container input {
    font-size: 18px;
    padding: 12px;
    height: 15px; /* Increase height for better touch target */
  }
}

.cool-highlight {
  background-color: #3498db   ; /* This is a vibrant purple, popular in modern designs */
  color: white; /* This ensures the text is readable on the blue background */
}

/* Or for an amber highlight: */
.amber-highlight {
  background-color: #ffbf00; /* This is a shade of amber */
  color: black; /* This ensures the text is readable on the amber background */
}

.delete-category-btn {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-category-btn:hover {
  background-color: #ff1a1a;
}

.image-controls .delete-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.image-controls .delete-btn:hover {
  background-color: #ff1a1a;
}